/* eslint-disable react/prop-types */
import * as React from "react";
import "./navbar.css";
import { Link } from "gatsby";
/**
 * Navbar for displaying name and email address accross entrie site
 *
 * @param {string} name my name
 * @param {string} email email address
 * @param {string} color color of the text
 * @returns
 */
const Navbar = ({ name, email, color = "white" }) => {
  return (
    <div className={`navbar navbar-${color}`}>
      <Link to={"/"}> {name}</Link>
      <div>{email}</div>
    </div>
  );
};

export default Navbar;

/* eslint-disable multiline-ternary */
import * as React from "react";
import Navbar from "../components/navbar";
import * as style from "./index.module.css";
import * as biostyle from "../components/bio.module.css";
import "animate.css";

// define constants
const NAME = "leo martinez";
const EMAIL = "bleo@mit.edu";
const releaseDate = new Date("09/02/2022");

const SecretMessage = () => {
  const currentTime = new Date();
  const millisLeft = releaseDate.getTime() - currentTime.getTime();
  const [showContent, setShowContent] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(millisLeft < 0);

  function getRemainingTime() {
    const result = { days: 0, hours: 0, minutes: 0 };
    const secondsLeft = Math.floor(millisLeft / 1000);
    const daysLeft = Math.floor(secondsLeft / (3600 * 24));
    const hoursLeft = Math.floor(secondsLeft / 3600 - daysLeft * 24);
    const minutesLeft = Math.floor(
      (secondsLeft - daysLeft * 3600 * 24 - hoursLeft * 3600) / 60
    );
    result.days = daysLeft;
    result.hours = hoursLeft;
    result.minutes = minutesLeft;
    return result;
  }
  const remainingTime = getRemainingTime();

  return (
    <div className={style.viewport}>
      <Navbar name={NAME} email={EMAIL}></Navbar>
      {showContent ? (
        showMessage ? (
          <div>
            So, apart from the fact that it bought me some time, making th
          </div>
        ) : (
          <div className={style.center_rows}>
            <div
              className={`${biostyle.medium_bold_text} animate__animated animate__fadeIn`}
            >
              THIS MESSAGE WILL UNLOCK IN
            </div>
            <div className={style.center_columns}>
              <div
                className={`${biostyle.small_bold_text} animate__animated animate__fadeIn animate__slower`}
              >
                {`${remainingTime.days} DAYS`}
              </div>
              <div
                className={`${biostyle.small_bold_text} animate__animated animate__fadeIn animate__slower`}
              >
                {`${remainingTime.hours} HOURS`}
              </div>
              <div
                className={`${biostyle.small_bold_text} animate__animated animate__fadeIn animate__slower`}
              >
                {`${remainingTime.minutes} MINUTES`}
              </div>
            </div>
            <div
              className={`${biostyle.small_bold_text} animate__animated animate__fadeIn`}
            >
              sorry sorry i just need a bit more time to write
            </div>
          </div>
        )
      ) : (
        <div
          className={`${biostyle.medium_bold_text} ${style.center_rows} animate__animated animate__fadeIn animate__slower`}
        >
          <div
            className={style.reveal_button}
            onClick={() => {
              setShowContent(true);
            }}
          >
            IS IT READY?
          </div>
        </div>
      )}
    </div>
  );
};

export default SecretMessage;
